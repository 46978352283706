// import React, { useState } from 'react';
// import { Row, Col, Button, Form, Image, Container } from 'react-bootstrap';
// import { Parser } from 'html-to-react';


// import img2 from "../../assets/images/card2.png";

// const Read = ({Cards}) => {
//     const [Card, setCard] = useState(JSON.parse(localStorage.getItem('CardData')) || Cards);
//     const tags = Card['Tags']?JSON.parse(Card['Tags']): [
//         'Green Hydrogen',
//         'Green Ammonia',
//         'National Hydrogen Policy',
//         'Renewable Energy',
//         'Solar Energy',
//         'Zero Emission',
//         'Energy Transition',
//         'Decarbonization',
//         'Renewable Purchase Obligation',
//         'Narendra Modi',
//         'COP Summit',
//     ];


//     const htmlContent = Card ? Card.Body : "";

//     const parser = new Parser();
//     const jsxContent = parser.parse(htmlContent);

//     return (
//         <>
//             <section className='py-5' >
//                 <div style={{ padding: "10px" }}>
//                     <Row className="justify-content-center" style={{ display: "flex", gap: "7px" }}>
//                         <Col lg={8} style={{ padding: '30px', border: '1px solid grey' }}>
//                             <div className="mb-4">
//                                 <Button style={{ border: '2px solid #a20000', backgroundColor: '#a20000', color: 'white', padding: '8px', fontSize: 'large' }}>{Card ? Card.Header : ""}</Button>
//                             </div>
//                             <Image src={Card ? Card.ImageURL : ""} alt="" fluid className="mb-4" />
//                             <>
//                                 {jsxContent}
//                             </>
//                             <Container>
//                                 <h1 className="text-left mb-4">Tags</h1>
//                                 <Row xs={1} sm={2} md={3}>
//                                     {tags.map((tag, index) => (
//                                         <Col key={index} className="mb-3">
//                                             <Button className="tag" style={{ backgroundColor: '#a20000', margin: '5px', width: '100%', height: '40px', padding: '0 15px', lineHeight: '40px' }}>{`${tag}`}</Button>
//                                         </Col>
//                                     ))}
//                                 </Row>
//                             </Container>

//                         </Col>
//                         <Col lg={3} style={{ padding: '30px', border: '1px solid grey', maxWidth: "100%", height: "100%" }}>
//                             <div className="mb-4">
//                                 <Form.Control type="text" placeholder="Search.." style={{ padding: '8px', fontSize: 'large' }} />
//                             </div>
//                             <h1 className="mb-4">Recent Posts</h1>
//                             <p>How Solar Installations Can Benefit Real Estate Developers</p>
//                             <p>Solar Construction: Need Of The Hour</p>

//                         </Col>
//                     </Row>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default Read;
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Image, Container } from 'react-bootstrap';
import { Parser } from 'html-to-react';
import { Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ContextAPI } from '../../ContextAPI/ContextAPI';
import ReactPlayer from 'react-player';

const Read = ({ Cards }) => {

    const { data } = ContextAPI();
    const [Card, setCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const { slug } = useParams();
    const location = useLocation();

    const defaultTags = [
        'Green Hydrogen',
        'Green Ammonia',
        'National Hydrogen Policy',
        'Renewable Energy',
        'Solar Energy',
        'Zero Emission',
        'Energy Transition',
        'Decarbonization',
        'Renewable Purchase Obligation',
        'Narendra Modi',
        'COP Summit',
    ];
    const latestPosts = data
        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Assuming 'date' is a field in your blog data
        .slice(0, 10);

    useEffect(() => {
        const fetchCardData = async () => {
            try {
                setLoading(true);
                // Clear previous data
                localStorage.removeItem('CardData');

                // First try to get all blogs
                const response = await axios.get('https://gautamsolar.us/admin/news?NoOfNews=100&Page=1');
                const allBlogs = response.data.data;

                // Find the blog post that matches the current slug
                const currentBlog = allBlogs.find(blog => {
                    const blogSlug = blog.Header.toLowerCase()
                        .replace(/[^a-z0-9\s-]/g, '')
                        .replace(/\s+/g, '-')
                        .replace(/-+/g, '-')
                        .trim();
                    return blogSlug === slug;
                });

                if (currentBlog) {
                    setCard(currentBlog);
                    localStorage.setItem('CardData', JSON.stringify(currentBlog));
                }
            } catch (error) {
                console.error('Error fetching post data:', error);
            } finally {
                setLoading(false);
            }
        };

        // Always fetch new data when the URL changes
        fetchCardData();

    }, [slug, location.pathname]); // Add location.pathname to dependencies

    const tags = Card && Card.Tags
        ? (typeof Card.Tags === 'string' ? JSON.parse(Card.Tags) : Card.Tags)
        : defaultTags;

    const renderHtmlContent = () => {
        if (!Card || !Card.Body) return null;

        try {
            const parser = new Parser();
            return parser.parse(Card.Body);
        } catch (error) {
            return (
                <div dangerouslySetInnerHTML={{ __html: Card.Body }} />
            );
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (!Card) {
        return <div className="text-center mt-5">Blog post not found</div>;
    }
    const formatDate = (dateStr) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateStr).toLocaleDateString('en-US', options);
    };

    return (
        <>
            <section className='py-5'>
                <div style={{ padding: "10px" }}>
                    <Row className="justify-content-center" style={{ display: "flex", gap: "7px" }}>
                        <Col lg={8} style={{ padding: '30px', border: '1px solid grey' }}>
                            <div className="mb-4">
                                <Button style={{ border: '2px solid #a20000', backgroundColor: '#a20000', color: 'white', padding: '8px', fontSize: 'large' }}>
                                    {Card.Header || ""}
                                </Button>
                                <p> {formatDate(Card.CreatedOn)} <span className="elementor-post-date">Published By: Raghvendra</span></p>
                            </div>
                            {Card.ImageURL && (
                                <Image src={Card.ImageURL} alt={Card.Header} fluid className="mb-4" />
                            )}
                            {Card.VideoUrl && (
                                <ReactPlayer
                                    url={Card.VideoUrl}
                                    controls
                                    width="100%"
                                    height="auto"
                                    className="mb-4"
                                />
                            )}
                            <div className="blog-content mb-4">
                                {renderHtmlContent()}
                            </div>

                            {/* <Container>
                                <h1 className="text-left mb-4">Tags</h1>
                                <Row xs={1} sm={2} md={3}>
                                    {tags.map((tag, index) => (
                                        <Col key={index} className="mb-3">
                                            <Button
                                                className="tag"
                                                style={{
                                                    backgroundColor: '#a20000',
                                                    margin: '5px',
                                                    width: '100%',
                                                    height: '40px',
                                                    padding: '0 15px',
                                                    lineHeight: '40px'
                                                }}
                                            >
                                                {tag}
                                            </Button>
                                        </Col>
                                    ))}
                                </Row>
                            </Container> */}
                            <Container>
                                <h1 className="text-left mb-4">Tags</h1>
                                <Row xs={1} sm={2} md={3}>
                                    {tags.map((tagItem, index) => {
                                        // Determine if tagItem is a string or an object
                                        const tagText = typeof tagItem === 'string' ? tagItem : tagItem.tag;
                                        const tagLink = typeof tagItem === 'object' && tagItem.link ? tagItem.link : null;

                                        return (
                                            <Col key={index} className="mb-3">
                                                <Button
                                                    className="tag"
                                                    style={{
                                                        backgroundColor: '#a20000',
                                                        margin: '5px',
                                                        width: '100%',
                                                        height: '40px',
                                                        padding: '0 15px',
                                                        lineHeight: '40px'
                                                    }}
                                                    onClick={() => tagLink && window.open(tagLink, '_blank')}
                                                >
                                                    {tagText}
                                                </Button>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Container>
                        </Col>
                        {/* <Col lg={3} style={{ padding: '30px', border: '1px solid grey', maxWidth: "100%", height: "100%" }}>
                            <div className="mb-4">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Search.." 
                                    style={{ padding: '8px', fontSize: 'large' }} 
                                />
                            </div>
                            <h1 className="mb-4">Recent Posts</h1>
                            <p>How Solar Installations Can Benefit Real Estate Developers</p>
                            <p>Solar Construction: Need Of The Hour</p>
                        </Col> */}
                        <Col lg={3} style={{ padding: '30px', border: '1px solid grey', maxWidth: "100%", height: "100%" }}>
                            <div className="mb-4">
                                <Form.Control
                                    type="text"
                                    placeholder="Search.."
                                    style={{ padding: '8px', fontSize: 'large' }}
                                />
                            </div>
                            <h1 className="mb-4">Recent Posts</h1>
                            {latestPosts.map((post, index) => {
                                const postSlug = post.Header.toLowerCase()
                                    .replace(/[^a-z0-9\s-]/g, '')
                                    .replace(/\s+/g, '-')
                                    .replace(/-+/g, '-')
                                    .trim();

                                return (
                                    <div key={index} className="recent-post">
                                        <Link to={`/${postSlug}`} style={{ textDecoration: 'none', color: '#a20000' }}>
                                            <h5 style={{ marginTop: '10px' }}>{post.Header}</h5>
                                            <Image
                                                src={post.ImageURL}
                                                alt={post.Header}
                                                fluid
                                                className="mb-4"
                                                style={{ width: '100%', maxHeight: '150px', objectFit: 'cover', borderRadius: '5px' }}
                                            />

                                        </Link>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                </div>
            </section>

            <style>
                {`
                    .blog-content {
                        font-size: 16px;
                        line-height: 1.6;
                        color: #333;
                    }
                    .blog-content p {
                        margin-bottom: 1rem;
                    }
                    .blog-content img {
                        max-width: 100%;
                        height: auto;
                        margin: 1rem 0;
                    }
                `}
            </style>
        </>
    );
};

export default Read;

